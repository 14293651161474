import React from 'react'

export default function Dashboard() {
  // console.log("dashboard");
  return (
    <div className='flex justify-center items-center text-richblack-100 text-3xl h-full'>
      Welcome to StudyNotaion
    </div>
  )
}
